<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} referrals</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} referrals that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.REFERRAL_CREATE)"
        to="/referrals/new"
        data-test="btn-new-referral"
        class="btn btn-primary ml-auto">
        New referral
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-referral" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Referrer</th>
                  <th>Friend</th>
                  <th class="text-center">Money earned</th>
                  <th class="text-center">Credit used</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.customer.user.email)});`"></span>
                  </td>
                  <td>
                    <router-link v-if="can(uiPermissions.CUSTOMERS_VIEW)" :to="`/users/${item.customer.id}`">
                      {{displayName(item.customer.user)}}
                    </router-link>
                    <span v-else>
                      {{displayName(item.customer.user)}}
                    </span>
                  </td>
                  <td>
                    <div>
                      <template v-if="item.referredCustomer">
                        <router-link
                          v-if="can(uiPermissions.CUSTOMERS_VIEW)"
                          :to="`/users/${item.referredCustomer.id}`">
                          {{displayName(item.referredCustomer.user)}}
                        </router-link>
                        <span v-else>
                          {{displayName(item.referredCustomer.user)}}
                        </span>
                      </template>
                      <template v-else>{{item.email}}</template>
                    </div>
                    <div class="small text-muted">{{status(item)}}</div>
                  </td>
                  <td class="text-center">
                    <div data-test="txt-amount-earned">
                      AED {{numeral(item.amount).format('0,0[.]00')}}
                    </div>
                    <div v-if="item.referredCustomer" class="small text-muted">
                      on {{moment(item.referredCustomer.createdAt).local().format('D MMM YYYY')}}
                    </div>
                  </td>
                  <td class="text-center">
                    <i v-if="item.used" class="fe fe-check"></i>
                  </td>
                  <td>
                    {{moment.utc(item.updatedAt).local().fromNow()}}
                  </td>
                  <td class="text-center"></td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="7">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {referrals} from '@/services';
import ReferralStatuses from '@hellochef/shared-js/enums/ReferralStatuses';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  methods: {
    fetchData(page) {
      return referrals.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
      });
    },
    status(item) {
      return Object.keys(ReferralStatuses).find(key => ReferralStatuses[key] === item.status);
    },
  },
};

</script>
